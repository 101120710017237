/*
    Panton Thin         - 30589C_20_0
    Panton Extra Light  - 30589C_16_0
    Panton Light        - 30589C_1A_0
    Panton Regular?     - 30589C_1B_0
    Panton Semi Bold    - 30589C_1E_0
    Panton Bold         - 30589C_11_0
    Panton Extra Bold   - 30589C_12_0
    Panton Black        - 30589C_10_0
    Panton Heavy        - 30589C_17_0
 */

@font-face {
  font-family: 'Panton';
  src: url('./Assets/webfonts/30589C_1A_0.eot');
  src: url('./Assets/webfonts/30589C_1A_0.eot?#iefix') format('embedded-opentype'),
  url('./Assets/webfonts/30589C_1A_0.woff2') format('woff2'),
  url('./Assets/webfonts/30589C_1A_0.woff') format('woff'),
  url('./Assets/webfonts/30589C_1A_0.ttf') format('truetype');
  /*font-weight: 400;*/
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./Assets/webfonts/30589C_1B_0.eot');
  src: url('./Assets/webfonts/30589C_1B_0.eot?#iefix') format('embedded-opentype'),
  url('./Assets/webfonts/30589C_1B_0.woff2') format('woff2'),
  url('./Assets/webfonts/30589C_1B_0.woff') format('woff'),
  url('./Assets/webfonts/30589C_1B_0.ttf') format('truetype');
  /*font-weight: 500;*/
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./Assets/webfonts/30589C_1E_0.eot');
  src: url('./Assets/webfonts/30589C_1E_0.eot?#iefix') format('embedded-opentype'),
  url('./Assets/webfonts/30589C_1E_0.woff2') format('woff2'),
  url('./Assets/webfonts/30589C_1E_0.woff') format('woff'),
  url('./Assets/webfonts/30589C_1E_0.ttf') format('truetype');
  /*font-weight: 600;*/ /* Semi Bold */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./Assets/webfonts/30589C_12_0.eot');
  src: url('./Assets/webfonts/30589C_12_0.eot?#iefix') format('embedded-opentype'),
  url('./Assets/webfonts/30589C_12_0.woff2') format('woff2'),
  url('./Assets/webfonts/30589C_12_0.woff') format('woff'),
  url('./Assets/webfonts/30589C_12_0.ttf') format('truetype');
  /*font-weight: 800;*/ /* ExtraBold */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('./Assets/webfonts/Muli-Regular.ttf');
  src: url('./Assets/webfonts/Muli-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

$black:#000000;
$white:#FFFFFF;
$blue: #0095d6;
$grey: #848f99;
$grey-light: #f4f5f6;

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Panton', 'Helvetica', 'Arial', sans-serif;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  color: $blue;
  margin: 0;
}

a {
  cursor: pointer;
  color: $blue;
  transition: color .3s;
  text-decoration: none;
  &:hover {
    color: darken($blue, 5%);
  }
}

.text-right {
  text-align: right;
}
.black {
  color: $black;
}

.App {

  .App-container {
    max-width: 960px;
    margin: 84px auto 0;
    padding-left: 1rem;
    padding-right: 1rem;

    .App-header {

      text-align: center;

      .App-logo {
        max-width: 122px;
        margin-bottom: 22px;
      }

      .App-title {
        text-align: center;
        margin-top: 0;
        margin-bottom: 6px;
        font-size: 2rem;
        line-height: 1.5;
        cursor: default;

        & + .App-subtitle {
          font-family: 'Muli', 'Panton', 'Helvetica', 'Arial', sans-serif;
          color: $black;
          font-weight: normal;
          font-size: 18px;
          line-height: 1.33;
        }
      }
    }

    .App-search-form {

      margin-top: 56px;

      input[type="text"] {
        display: block;
        border: 0;
        border-radius: 20px;
        background-color: $grey-light;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23848F99' stroke-linecap='round' stroke-linejoin='round' d='M18.174 23.348a5.174 5.174 0 1 0 0-10.348 5.174 5.174 0 0 0 0 10.348zM27 26.798l-4.802-4.803'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: left 5px center;
        padding: 0 15px 0 45px;
        width: calc(100% - 60px);
        max-width: 404px;
        height: 40px;
        line-height: .93;
        font-size: .975rem;
        -webkit-appearance: none;
        margin: 0 auto 28px;
        letter-spacing: -0.5px;

        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $grey;
          font-weight: 300;
        }
      }

    }

    .tag-filter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;

      .tag-item {
        display: block;
        cursor: pointer;
        padding: 10px 5px;
        transition: opacity .3s;

        .tag-item-inner {
          text-transform: uppercase;
          color: white;
          border-radius: 5px;
          padding: 10px 10px 8px;
          font-size: .75rem;
          font-weight: bold;
          line-height: 1.17;
          text-align: center;
          transition: opacity .3s, color .3s;
        }

        &:hover {
          opacity: 0.9;
        }

        & > input[type=checkbox] {
          display: none;

          &:not(:checked) ~ span {
            opacity: 0.25;
            color: black;
          }
        }
      }
    }

    .masonry-list {
      margin: 1.75vw auto 3.5vw;
      display: flex;
      margin-left: -11px;
      width: auto;

      .masonry-list-grid-column {
        padding-left: 11px;
        background-clip: padding-box;

        & > .masonry-item {
          box-sizing: border-box;
          flex: 1 1 298px;
          align-self: auto;
          margin: 24px 5px;
          transition: opacity .3s, transform .3s, max-width .3s, max-height .3s, margin .3s, box-shadow .3s;
          perspective: 1000px;
          position: relative;

          .symbol-link {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDk1ZDY7Ij48cGF0aCBkPSJNIDUgMyBDIDMuOTA2OTM3MiAzIDMgMy45MDY5MzcyIDMgNSBMIDMgMTkgQyAzIDIwLjA5MzA2MyAzLjkwNjkzNzIgMjEgNSAyMSBMIDE5IDIxIEMgMjAuMDkzMDYzIDIxIDIxIDIwLjA5MzA2MyAyMSAxOSBMIDIxIDEyIEwgMTkgMTIgTCAxOSAxOSBMIDUgMTkgTCA1IDUgTCAxMiA1IEwgMTIgMyBMIDUgMyB6IE0gMTQgMyBMIDE0IDUgTCAxNy41ODU5MzggNSBMIDguMjkyOTY4OCAxNC4yOTI5NjkgTCA5LjcwNzAzMTIgMTUuNzA3MDMxIEwgMTkgNi40MTQwNjI1IEwgMTkgMTAgTCAyMSAxMCBMIDIxIDMgTCAxNCAzIHoiPjwvcGF0aD48L3N2Zz4=') 50% 50% no-repeat;
            background-size: 100%;
            opacity: 0;
            transition: opacity .3s, filter .3s;
            vertical-align: text-top;
            position: relative;
            top: -1px;
          }

          .masonry-item-abstract {
            font-family: 'Muli', 'Panton', 'Helvetica', 'Arial', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            margin: 0;
            color: $black;
            margin-bottom: 20px;
            cursor: default;
          }

          .button-link {
            background: transparent;
            border: 0;
            -webkit-appearance: none;
            color: $blue;
            cursor: pointer;
            font-size: 1rem;
            padding: 0 4px;
            &:focus {
              outline: none;
            }
          }

          & > .masonry-item-inner {
            transition: .6s;
            transform-style: preserve-3d;
            position: relative;
            height: 100%;

            & > .masonry-item-front, & > .masonry-item-back {
              background-color: white;
              backface-visibility: hidden;
              top: 0;
              left: 0;
              box-shadow: 0 20px 60px -10px hsla(0, 0%, 0%, 0.1);
              transition: box-shadow .3s;
            }

            & > .masonry-item-front {
              width: calc(100% - 40px);
              height: calc(100% - 40px);
              padding: 20px;

              & > .masonry-item-title {
                font-weight: bold;
                font-size: 1.25rem;
                margin-bottom: 0.6rem;
              }
              & > .tag-filter {
                justify-content: flex-start;
                margin-left: -5px;
                margin-right: -5px;
                margin-bottom: -5px;
              }

            }
            & > .masonry-item-back {
              width: 100%;
              height: 100%;
              position: absolute;
              cursor: pointer;
              transform: rotateY(180deg);
              & > .masonry-item-abstract {
                margin: 0;
                padding: 20px;
                max-height: calc(100% - 40px);
                overflow-x: hidden;
                overflow-y: auto;
                cursor: pointer;
                & > p {
                  margin: 0;
                }
              }
            }
          }

          &:hover {
            transform: translateY(-1%);
            z-index: 1;

            .masonry-item-front, .masonry-item-back {
              box-shadow: 0 20px 60px -10px hsla(0, 0%, 0%, 0.25);
            }

            &:not(.flipped) {
              .symbol-link {
                opacity: 1;
              }
            }
            .masonry-item-title {
              a:hover {
                .symbol-link {
                  filter: brightness(80%);
                }
              }
            }
          }

          &.flipped > .masonry-item-inner {
            transform: rotateY(180deg);
          }

          &.hidden {
            opacity: 0;
            transform: scale(0);
            margin: 0;
            order: 9999;
          }
        }
      }
    }
  }

  .App-footer {
    padding: 48px 0 0;
    background-color: $grey-light;
    font-family: 'Muli', 'Panton', 'Helvetica', 'Arial', sans-serif;
    text-align: center;
    line-height: 1.5;
    cursor: default;

    .App-footer-note {
      font-family: 'Panton', 'Helvetica', 'Arial', sans-serif;
      font-size: 20px;
      margin-bottom: 18px;
      font-weight: 600;
    }

    .App-logo {
      max-width: 86px;
      margin-bottom: 12px;
    }

    .App-social {
      margin: 2.75rem 0 2.65rem;
      .icon-social {
        margin: 0 0.775rem;
      }
      a:first-child > .icon-social {
        margin-left: 0;
      }
      a:last-child > .icon-social {
        margin-right: 0;
      }
    }

    .App-copyright {
      color: $black;
      background-color: $white;
      padding: 24px 0;
      line-height: 1.5;
      letter-spacing: 0.05px;
    }
  }
}

.text-loader {
  position: absolute;
  top: 45%;
  left: 50%;
}
.text-loader::after {
  content: '';
  color: $blue;
  font-size: 4rem;
  line-height: 0.7;
  animation: text-spinner .5s steps(4) infinite;
}

@keyframes text-spinner {
  0%    {content: '◟'}
  25%   {content: '◜'}
  50%   {content: '◝'}
  75%   {content: '◞'}
  100%  {content: '◟'}
}
